var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"chart-by-users"},[(_vm.users.name.length)?_c('vue-apex-charts',{attrs:{"type":"bar","options":{
      plotOptions: {
        bar: {
          dataLabels: {

          },
        },
      },
      xaxis: {
        categories: _vm.users.name
      },
      ..._vm.chartOptions
    },"series":[{
      name: _vm.$t('Total Amount'),
      data: _vm.users.amount,
    }, {
      name: _vm.$t('Total Invoiced Amount'),
      data: _vm.users.invoicedAmount,
    }]}}):_c('div',{staticClass:"text-center"},[_c('span',[_vm._v(_vm._s(_vm.$t('table.no-data-to-show')))])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }