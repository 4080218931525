var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-overlay',{attrs:{"show":_vm.isProcessing}},[_c('b-row',{attrs:{"id":"tour-payment-history"}},[_c('b-col',{attrs:{"md":"8"}},[_c('b-table',{attrs:{"id":"payment-history-table","items":_vm.rows,"fields":_vm.columns,"responsive":"","selectable":""},on:{"row-clicked":row => _vm.showDetails(row)},scopedSlots:_vm._u([{key:"head()",fn:function(row){return [_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.$t(row.label)))])]}},{key:"cell(id)",fn:function(row){return [_vm._v(" "+_vm._s(row.index + 1)+" ")]}},{key:"cell(name)",fn:function(row){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('b-avatar',{attrs:{"src":_vm.getAvatar(row.item.avatar),"variant":_vm.getRandomVariant(),"text":_vm.getInitials(row.item.name)}}),_c('p',{staticClass:"mb-0 ml-1"},[_vm._v(" "+_vm._s(row.item.name)+" ")])],1)]}},{key:"cell(totalWorkedTime)",fn:function(row){return [_vm._v(" "+_vm._s(_vm.CONVERT_HM(row.item.activityDuration))+" ")]}},{key:"cell(totalManualTime)",fn:function(row){return [_vm._v(" "+_vm._s(_vm.CONVERT_HM(row.item.manualDuration))+" ")]}},{key:"cell(totalAmount)",fn:function(row){return [_vm._v(" "+_vm._s(_vm.formatAmount(row.item.activityAmount))+" ")]}},{key:"cell(totalInvoiced)",fn:function(row){return [_vm._v(" "+_vm._s(_vm.formatAmount(_vm.ACCUMULATE(row.item.chartData.amount)))+" ")]}},{key:"cell(actions)",fn:function(row){return [_c('feather-icon',{attrs:{"icon":row.detailsShowing ? 'ChevronUpIcon' : 'ChevronDownIcon',"size":"20"}})]}},{key:"row-details",fn:function(row){return [_c('b-card',{staticClass:"payment-history-chart"},[(row.item.chartData.invoicedAt.length)?_c('b-row',{staticClass:"d-flex"},[_c('b-col',{attrs:{"md":"9"}},[_c('vue-apex-charts',{attrs:{"type":"bar","options":{
                    ..._vm.chartOptions,
                    xaxis: {
                      categories: row.item.chartData.invoicedAt
                    },
                    plotOptions: {
                      bar: {
                        dataLabels: {
                          position: 'top',
                        },
                      },
                    },
                    tooltip: {
                      custom: ({
                        series, seriesIndex, dataPointIndex, w,
                      }) => {
                        return _vm.apexTooltip(series, seriesIndex, dataPointIndex, w, row.item.chartData)
                      },
                      fixed: {
                        enabled: true,
                        position: 'bottomRight'
                      },
                    },
                  },"series":[{
                    name: _vm.$t('Total Amount'),
                    data: row.item.chartData.amount,
                  }]}})],1),_c('b-col',{attrs:{"md":"3"}},[_c('p',{staticClass:"small"},[_vm._v(" "+_vm._s(_vm.$t('Total Amount'))+": "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(`${_vm.currency}${row.item.activityAmount.toFixed(2)}`)+" ")])]),_c('p',{staticClass:"small"},[_vm._v(" "+_vm._s(_vm.$t('Invoiced Amount'))+": "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(`${_vm.currency}${row.item.chartData.amount.reduce((a, b) => Number(a) + Number(b), 0).toFixed(2)}`)+" ")])])])],1):_c('div',{staticClass:"text-center"},[_c('span',[_vm._v(_vm._s(_vm.$t('table.no-data-to-show')))])])],1)]}}])}),(_vm.totalUsers > _vm.perPage)?_c('b-pagination',{staticClass:"float-right pb-4",attrs:{"total-rows":_vm.totalUsers,"per-page":_vm.perPage,"aria-controls":"payment-history-table"},on:{"input":function($event){return _vm.$emit('get-data', _vm.currentPage, _vm.perPage)}},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-card',{attrs:{"no-body":""}},[(_vm.users)?_c('chart-by-users',{staticClass:"chart-by-users",attrs:{"chart-options":_vm.chartOptions,"users":_vm.users}}):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }